import React, { useEffect } from 'react';
import {
  ActionContainer,
  CaptionText,
  Content,
  DeleteText,
  GalleryIcon,
  IconContainer,
  MediaBundleCard,
  MediaStatsContainer,
  SaleContainer,
  SaleText,
  VideoGalleryIcon,
} from './MediaBundle.styles';
import { Text16Regular } from '../utils/texts/text.styles';
import { MEDIA_TYPE } from '../../utils/constants';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { useConfirmationModal } from '../utils/modals/ConfirmationModal/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError, notifyInfo } from '../../utils/notify';
import { deleteMediaBundle } from '../../store/slices/influencer/asyncThunks';
import { selectDeleteMediaBundlePending } from '../../store/slices/influencer/slice';

const REF_TYPE = 'media-bundle';

const MediaBundle = ({ mediaBundle, onClick, index, moveMediaBundle, onDragging }) => {
  const deleteMediaBundlePending = useSelector(selectDeleteMediaBundlePending);
  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();
  const dispatch = useDispatch();

  const [, ref] = useDrag({
    type: REF_TYPE,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: REF_TYPE,
    drop: draggedItem => {
      moveMediaBundle(draggedItem.index, index);
      draggedItem.index = index;
    },
    hover: draggedItem => {
      if (draggedItem.index !== index) {
        onDragging(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  useEffect(() => {
    setConfirmationModalLoader(deleteMediaBundlePending);
  }, [deleteMediaBundlePending, setConfirmationModalLoader]);

  const onDeleteMediaBundleClick = e => {
    openModal(
      'Delete Media Bundle',
      `Are you sure you want to delete media bundle?`,
      'Delete',
      () => {
        dispatch(deleteMediaBundle({ mediaBundleId: mediaBundle.id }))
          .unwrap()
          .then(() => {
            notifyInfo('Media bundle deleted successfully!');
            closeModal();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
    e.stopPropagation();
  };

  const photosSize = mediaBundle.mediaGroup.filter(media => media.type === MEDIA_TYPE.PHOTO).length;
  const videosSize = mediaBundle.mediaGroup.filter(media => media.type === MEDIA_TYPE.VIDEO).length;

  return (
    <MediaBundleCard ref={node => ref(drop(node))} onClick={onClick}>
      <SaleContainer>
        <SaleText>{mediaBundle.sale}% OFF</SaleText>
      </SaleContainer>
      <Content>
        <CaptionText>{mediaBundle.caption}</CaptionText>
        <MediaStatsContainer>
          <IconContainer>
            <GalleryIcon />
            <Text16Regular>{photosSize}</Text16Regular>
          </IconContainer>
          <IconContainer>
            <VideoGalleryIcon />
            <Text16Regular>{videosSize}</Text16Regular>
          </IconContainer>
        </MediaStatsContainer>
      </Content>
      <ActionContainer onClick={onDeleteMediaBundleClick}>
        <DeleteIcon />
        <DeleteText>Delete</DeleteText>
      </ActionContainer>
    </MediaBundleCard>
  );
};

export default MediaBundle;

import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/a/influencers';

class InfluencerService {
  fetchInfluencers = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching influencers.');
    }
  };

  fetchInfluencerById = async id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${id}`);
      throwErrorIfWrongStatus(response, 200);
      const { influencer } = response.data.data;
      return influencer;
    } catch (err) {
      throw handleError(err, 'Error occured while fetching influencers.');
    }
  };

  createInfluencer = async formData => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}`, formData, { headers });
      throwErrorIfWrongStatus(response, 201);
      const { businessConnectionId } = response.data.data;
      return businessConnectionId;
    } catch (err) {
      throw handleError(err, 'Error occured while creating influencer.');
    }
  };

  editInfluencer = async (influencerId, formData) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.put(`${BASE_URL}/${influencerId}`, formData, { headers });
      throwErrorIfWrongStatus(response, 200);
      const { influencer } = response.data.data;
      return influencer;
    } catch (err) {
      throw handleError(err, 'Error occured while editing influencer.');
    }
  };

  editPrompts = async (influencerId, body) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/${influencerId}/prompts`, body);
      throwErrorIfWrongStatus(response, 200);
      const { influencer } = response.data.data;
      return influencer;
    } catch (err) {
      throw handleError(err, 'Error occurred while editing influencer prompts.');
    }
  };

  fetchInfluencerAlbumMedia = async (influencerId, mediaType, mediaCategory) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/album-media/${influencerId}?mediaType=${mediaType.toLowerCase()}&mediaCategory=${mediaCategory.toLowerCase()}`,
      );
      throwErrorIfWrongStatus(response, 200);
      const { data } = response.data.data;
      return { data };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencers.');
    }
  };

  uploadInfluencerAlbumMedia = async (influencerId, mediaCategory, formData, mediaType) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(
        `${BASE_URL}/${influencerId}/${mediaType.toLowerCase()}/${mediaCategory.toLowerCase()}`,
        formData,
        {
          headers,
        },
      );
      throwErrorIfWrongStatus(response, 200);
      const { media } = response.data.data;
      return { media, mediaCategory };
    } catch (err) {
      throw handleError(err, 'Error occurred while uploading album media');
    }
  };

  editInfluencerAlbumMedia = async (influencerId, albumMediaId, mediaCategory, body, mediaType) => {
    try {
      const response = await axiosApiClient.put(
        `${BASE_URL}/${influencerId}/${mediaType.toLowerCase()}/${mediaCategory.toLowerCase()}/${albumMediaId}`,
        body,
      );
      throwErrorIfWrongStatus(response, 200);
      const { media } = response.data.data;
      return { media, mediaCategory };
    } catch (err) {
      throw handleError(err, 'Error occured while editing album media');
    }
  };

  fetchInfluencerVariables = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/variables`);
      throwErrorIfWrongStatus(response, 200);
      const { businessConnections, albumMediaTags } = response.data.data;
      return { businessConnections, albumMediaTags };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching influencer variables');
    }
  };

  changeAlbumMediaPosition = async (influencerId, mediaType, mediaPositions) => {
    try {
      const body = { mediaType, mediaPositions };
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/media/change-position`, body);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occured while uploading album media');
    }
  };

  fetchInfluencerPremiumAlbumMedia = async influencerId => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/premium-media/${influencerId}`);
      throwErrorIfWrongStatus(response, 200);
      const { photos, videos } = response.data.data;
      return { photos, videos };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching premium album media.');
    }
  };

  createMediaBundle = async (influencerId, media, sale, caption, aiDescription) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/media-bundle`, {
        media,
        sale,
        caption,
        aiDescription,
      });
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while creating bundle.');
    }
  };

  fetchInfluencerMediaBundles = async influencerId => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/media-bundle/${influencerId}`);
      throwErrorIfWrongStatus(response, 200);
      const { bundles } = response.data.data;
      return bundles;
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching bundles.');
    }
  };

  fetchMediaBundleById = async (influencerId, mediaBundleId) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${influencerId}/media-bundle/${mediaBundleId}`);
      throwErrorIfWrongStatus(response, 200);
      const { bundle } = response.data.data;
      return bundle;
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching bundle.');
    }
  };

  changeMediaBundlePosition = async (influencerId, mediaPositions) => {
    try {
      const body = { mediaPositions };
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/media-bundle/change-position`, body);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occured while changing bundle position');
    }
  };

  deleteInfluencerAlbumMedia = async (influencerId, albumMediaId, mediaType, mediaCategory, mediaTag) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/${influencerId}/${mediaType.toLowerCase()}/${mediaCategory.toLowerCase()}/${albumMediaId}`,
      );
      throwErrorIfWrongStatus(response, 200);
      return { albumMediaId, mediaCategory, mediaTag };
    } catch (err) {
      throw handleError(err, 'Error occured while deleting album media');
    }
  };

  deleteMediaBundle = async (influencerId, mediaBundleId) => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${influencerId}/media-bundle/${mediaBundleId}`);
      throwErrorIfWrongStatus(response, 200);
      return { mediaBundleId };
    } catch (err) {
      throw handleError(err, 'Error occured while deleting media bundle');
    }
  };

  cancelInfluencerMassDM = async influencerId => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/cancel-mass-dm`);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occured while cancelling mass dm');
    }
  };

  triggerInfluencerMassDM = async influencerId => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/trigger-mass-dm`);
      throwErrorIfWrongStatus(response, 200);
      const { massDM } = response.data.data;
      return { massDM };
    } catch (err) {
      throw handleError(err, 'Error occured while triggering mass dm');
    }
  };

  fetchCaptionRecommendations = async (influencerId, photoId) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${influencerId}/caption-recommendations/${photoId}`);
      throwErrorIfWrongStatus(response, 200);
      const { captions } = response.data.data;
      return captions;
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching caption recommendations.');
    }
  };
}

const influencerService = new InfluencerService();
export default influencerService;

import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditAlbumMediaPending,
  selectFetchInfluencerVariablesInfoData,
  selectUploadAlbumMediaPending,
} from '../../../store/slices/influencer/slice';
import { notifyError, notifyInfo } from '../../../utils/notify';
import FileInput from '../../utils/inputs/FileInput/FileInput';
import {
  MEDIA_CATEGORY,
  MEDIA_TYPE,
  UPLOAD_FILE_CONSTRAINTS,
  VALUE_OF_ONE_STAR_IN_USD,
} from '../../../utils/constants';
import { editInfluencerAlbumMedia, uploadInfluencerAlbumMedia } from '../../../store/slices/influencer/asyncThunks';
import { uploadPremiumAlbumMediaFormSchema } from '../../../forms/schemas/albumMedia.schema';
import { FormInput, FormNumericInput, FormSelect } from '../../../forms/form.styles';
import { GenerateText, PriceInUsd, Row } from './UploadPremiumPhotoFormModal.styles';
import GetCaptionRecommendationsModal from '../GetCaptionRecommendationsModal/GetCaptionRecommendationsModal';

const UploadPremiumPhotoFormModal = ({ modalRef, selectedAlbumMedia, onAfterClose, customOnSubmit }) => {
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);
  const uploadAlbumMediaPending = useSelector(selectUploadAlbumMediaPending);
  const editAlbumMediaPending = useSelector(selectEditAlbumMediaPending);
  const getCaptionRecommendationsModalRef = useRef();
  const [selectedMediaId, setSelectedMediaId] = useState(null);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      albumMedia: null,
      cost: '',
      aiDescription: '',
      caption: '',
      fileId: '',
      tag: null,
      contentId: '',
    },
    delayError: 300,
    resolver: yupResolver(uploadPremiumAlbumMediaFormSchema),
  });

  const watchCost = watch('cost');

  useEffect(() => {
    if (selectedAlbumMedia && influencerVariables?.albumMediaTags) {
      const albumMediaTag = influencerVariables.albumMediaTags.find(tag => {
        if (selectedAlbumMedia.tag) {
          if (typeof selectedAlbumMedia.tag === 'string') {
            return tag.id === selectedAlbumMedia.tag;
          } else {
            return tag.id === selectedAlbumMedia.tag.value;
          }
        } else {
          return false;
        }
      });
      const { aiDescription, caption, cost, url, albumMedia, fileId, contentId } = selectedAlbumMedia;
      setValue('albumMedia', url ? url : albumMedia);
      setValue('cost', cost);
      setValue('aiDescription', aiDescription);
      setValue('caption', caption);
      setValue('fileId', fileId);
      if (albumMediaTag) {
        setValue('tag', { value: albumMediaTag.id, label: albumMediaTag.name });
      }
      if (contentId) {
        setValue('contentId', contentId);
      }
    } else {
      reset();
    }
  }, [selectedAlbumMedia, setValue, reset, influencerVariables?.albumMediaTags]);

  const onGenerateClick = () => {
    setSelectedMediaId(selectedAlbumMedia.id);
    getCaptionRecommendationsModalRef.current.show();
  };

  const onSubmit = data => {
    if (!!customOnSubmit) {
      customOnSubmit(data);
      modalRef.current.hide();
      return;
    }

    if (selectedAlbumMedia) {
      dispatch(
        editInfluencerAlbumMedia({
          ...data,
          albumMediaId: selectedAlbumMedia.id,
          mediaType: MEDIA_TYPE.PHOTO,
          mediaCategory: MEDIA_CATEGORY.PREMIUM,
          tag: data.tag.value,
        }),
      )
        .unwrap()
        .then(_ => {
          modalRef.current.hide();
          notifyInfo('Premium photo edited successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    } else {
      dispatch(
        uploadInfluencerAlbumMedia({
          ...data,
          mediaType: MEDIA_TYPE.PHOTO,
          mediaCategory: MEDIA_CATEGORY.PREMIUM,
          tag: data.tag.value,
        }),
      )
        .unwrap()
        .then(_ => {
          modalRef.current.hide();
          notifyInfo('Premium photo uploaded successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  return (
    <FormModal
      ref={modalRef}
      title={selectedAlbumMedia ? 'Edit Album Media (Premium Photo)' : 'Upload Album Media (Premium Photo)'}
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        onAfterClose();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText={selectedAlbumMedia ? 'Edit' : 'Submit'}
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={uploadAlbumMediaPending || editAlbumMediaPending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <Controller
          name="albumMedia"
          control={control}
          render={({ field }) => {
            return (
              <FileInput
                disabled={!customOnSubmit && selectedAlbumMedia}
                label="Album media"
                value={field.value}
                onChange={field.onChange}
                type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
                error={errors.albumMedia?.message}
                increasedFileHeight
              />
            );
          }}
        />
        <FormInput label="File ID" placeholder="Enter file ID" {...register('fileId')} error={errors.fileId?.message} />
        <Controller
          name="tag"
          control={control}
          render={({ field }) => (
            <FormSelect
              placeholder="Select tag"
              label="Tag"
              options={
                influencerVariables?.albumMediaTags
                  ? influencerVariables.albumMediaTags.map(tag => ({ value: tag.id, label: tag.name }))
                  : []
              }
              selectedOption={field.value}
              handleChange={value => field.onChange(value)}
              error={errors.tag?.message}
            />
          )}
        />
        <Row>
          <FormNumericInput
            label="Cost (stars)"
            {...register('cost', {
              valueAsNumber: true,
            })}
            error={errors.cost?.message}
          />
          <PriceInUsd>= ${((watchCost || 0) * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}</PriceInUsd>
        </Row>
        {!customOnSubmit && selectedAlbumMedia && (
          <FormInput
            label="Content ID"
            placeholder="Enter content ID"
            {...register('contentId')}
            error={errors.contentId?.message}
          />
        )}
        <FormInput
          label="AI description"
          placeholder="Enter AI description"
          {...register('aiDescription')}
          error={errors.aiDescription?.message}
        />
        <Row>
          <FormInput
            label="Caption"
            placeholder="Enter caption"
            {...register('caption')}
            error={errors.caption?.message}
          />
          {selectedAlbumMedia && <GenerateText onClick={onGenerateClick}>Generate</GenerateText>}
        </Row>
      </form>
      <GetCaptionRecommendationsModal
        modalRef={getCaptionRecommendationsModalRef}
        mediaId={selectedMediaId}
        setMediaId={setSelectedMediaId}
        setValue={setValue}
      />
    </FormModal>
  );
};

export default UploadPremiumPhotoFormModal;

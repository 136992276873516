import styled from 'styled-components';
import { ReactComponent as DeleteIconSvg } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIconSvg } from '../../assets/icons/edit-dark.svg';

export const Container = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 1.4rem;
`;

export const ActionContainer = styled.div`
  position: absolute;
  top: 0.8rem;
  width: 3.2rem;
  height: 3.2rem;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  background-color: #ffffff80;
  /* opacity: 0.8; */
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const PrimaryActionContainer = styled(ActionContainer)`
  right: 0.8rem;
`;

export const SecondaryActionContainer = styled(ActionContainer)`
  right: 4.8rem;
`;

export const EditIcon = styled(EditIconSvg)``;

export const DeleteIcon = styled(DeleteIconSvg)`
  fill: #000;
`;

import React, { useEffect } from 'react';
import { FlexMain } from '../../../../components/utils/utils.styles';
import { Text20Bold } from '../../../../components/utils/texts/text.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCancelInfluencerMassDMPending,
  selectSelectedInfluencer,
  selectTriggerInfluencerMassDMPending,
} from '../../../../store/slices/influencer/slice';
import { Container, StyledButton } from './InfluencerMassDmTab.styles';
import { useConfirmationModal } from '../../../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import { notifyError, notifyInfo } from '../../../../utils/notify';
import { cancelInfluencerMassDM, triggerInfluencerMassDM } from '../../../../store/slices/influencer/asyncThunks';

const InfluencerMassDmTab = () => {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const cancelInfluencerMassDMPending = useSelector(selectCancelInfluencerMassDMPending);
  const triggerInfluencerMassDMPending = useSelector(selectTriggerInfluencerMassDMPending);
  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();
  const dispatch = useDispatch();

  useEffect(() => {
    setConfirmationModalLoader(cancelInfluencerMassDMPending);
  }, [cancelInfluencerMassDMPending, setConfirmationModalLoader]);

  useEffect(() => {
    setConfirmationModalLoader(triggerInfluencerMassDMPending);
  }, [triggerInfluencerMassDMPending, setConfirmationModalLoader]);

  const onCancelMassDM = () => {
    openModal(
      'Cancel Mass DM',
      `Are you sure you want to cancel mass DM?`,
      'Cancel',
      () => {
        dispatch(cancelInfluencerMassDM())
          .unwrap()
          .then(() => {
            notifyInfo('Mass DM cancelled successfully!');
            closeModal();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Close',
      () => {
        closeModal();
      },
    );
  };

  const onSendMassDM = () => {
    openModal(
      'Send Mass DM',
      `Are you sure you want to send mass DM?`,
      'Send',
      () => {
        dispatch(triggerInfluencerMassDM())
          .unwrap()
          .then(() => {
            notifyInfo('Mass DM sent successfully!');
            closeModal();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
  };

  return (
    <FlexMain>
      {selectedInfluencer.massDM.jobId ? (
        <Container>
          <Text20Bold>Next Mass DM: {new Date(selectedInfluencer.massDM.timestamp).toLocaleString()} </Text20Bold>
          <StyledButton title="Cancel Mass DM" onClick={onCancelMassDM} />
        </Container>
      ) : (
        <StyledButton title="Send Mass DM" onClick={onSendMassDM} />
      )}
    </FlexMain>
  );
};

export default InfluencerMassDmTab;

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createInfluencerStepBasicInfoFormSchema } from '../../../../../forms/schemas/influencer.schema';
import { CreateInfluencerForm, Footer, FooterRightButton } from '../../CreateInfluencerPage.styles';
import { ReactComponent as ForwardIcon } from '../../../../../assets/icons/forward.svg';
import {
  FormDatePicker,
  FormFileInput,
  FormInput,
  FormNumericInput,
  FormSelect,
} from '../../../../../forms/form.styles';
import {
  ActiveProviderText,
  Container,
  ContainerLeftPart,
  ContainerRightPart,
  RatioContainer,
  UploadRow,
} from './CreateInfluencerPageStepBasicInfo.styles';
import { UPLOAD_FILE_CONSTRAINTS, VOICE_PROVIDER_TITLE } from '../../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchInfluencerVariablesInfoData } from '../../../../../store/slices/influencer/slice';
import { fetchInfluencerVariables } from '../../../../../store/slices/influencer/asyncThunks';
import CropImageModal from '../../../../../components/utils/modals/CropImageModal/CropImageModal';
import HandleVoiceProvidersModal from '../../../../../components/modals/HandleVoiceProvidersModal/HandleVoiceProvidersModal';
import { Text16Regular } from '../../../../../components/utils/texts/text.styles';
import { MainButton } from '../../../../../components/utils/buttons/Button/Button';
import ErrorContainer from '../../../../../components/utils/ErrorContainer/ErrorContainer';

function CreateInfluencerPageStepBasicInfo({ formData, onAfterSubmit, step, influencerRequest }) {
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);
  const cropImageRef = useRef();
  const handleVoiceProvidersModalRef = useRef();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      name: '',
      nickname: '',
      username: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      dateOfBirth: null,
      profilePhoto: null,
      businessConnection: null,
      paymentRatio: 0.5,
      voiceResponseRatio: 0.5,
      dickRatePrice: 20,
      sextingPrice: 20,
      lipSyncPrice: 20,
      textOnBodyPrice: 20,
      tippingPrice: 299,
      telegramChannelUrl: '',
      voiceId: '',
      influencerRequestId: null,
      voiceProviders: null,
    },
    delayError: 300,
    resolver: yupResolver(createInfluencerStepBasicInfoFormSchema),
  });

  const watchProfilePhoto = watch('profilePhoto');
  const watchVoiceProviders = watch('voiceProviders');

  useEffect(() => {
    if (!influencerVariables) {
      dispatch(fetchInfluencerVariables());
    }
  }, [dispatch, influencerVariables]);

  useEffect(() => {
    if (influencerRequest && !formData.basicInfo) {
      setValue('influencerRequestId', influencerRequest.id);
      setValue('name', influencerRequest.name);
      setValue('email', influencerRequest.email);
      setValue('profilePhoto', influencerRequest.profilePhoto);
      setValue('dateOfBirth', influencerRequest.dateOfBirth);
    }
  }, [influencerRequest, setValue, formData.basicInfo]);

  useEffect(() => {
    if (formData.basicInfo) {
      setValue('name', formData.basicInfo.name);
      setValue('nickname', formData.basicInfo.nickname);
      setValue('username', formData.basicInfo.username);
      setValue('email', formData.basicInfo.email);
      setValue('password', formData.basicInfo.password);
      setValue('passwordConfirmation', formData.basicInfo.passwordConfirmation);
      setValue('dateOfBirth', formData.basicInfo.dateOfBirth);
      setValue('profilePhoto', formData.basicInfo.profilePhoto);
      setValue('businessConnection', formData.basicInfo.businessConnection);
      setValue('paymentRatio', formData.basicInfo.paymentRatio);
      setValue('voiceResponseRatio', formData.basicInfo.voiceResponseRatio);
      setValue('dickRatePrice', formData.basicInfo.dickRatePrice);
      setValue('sextingPrice', formData.basicInfo.sextingPrice);
      setValue('lipSyncPrice', formData.basicInfo.lipSyncPrice);
      setValue('textOnBodyPrice', formData.basicInfo.textOnBodyPrice);
      setValue('tippingPrice', formData.basicInfo.tippingPrice);
      if (formData.basicInfo.telegramChannelUrl) {
        setValue('telegramChannelUrl', formData.basicInfo.telegramChannelUrl);
      }
      setValue('voiceProviders', formData.basicInfo.voiceProviders);
      setValue('influencerRequestId', formData.basicInfo.influencerRequestId);
    }
  }, [formData.basicInfo, setValue]);

  useEffect(() => {
    if (watchProfilePhoto) {
      if (typeof watchProfilePhoto !== 'string') {
        cropImageRef.current.show();
      }
    }
  }, [watchProfilePhoto]);

  const onSubmit = data => {
    onAfterSubmit(data, step);
  };

  return (
    <>
      <CreateInfluencerForm>
        <Container>
          <ContainerLeftPart>
            <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
            <FormInput
              label="Nickname"
              placeholder="Enter nickname"
              {...register('nickname')}
              error={errors.nickname?.message}
            />
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <FormInput
                  label="Username"
                  placeholder="Enter username"
                  onChange={e => field.onChange(e.target.value.toLowerCase())}
                  value={field.value}
                  error={errors.username?.message}
                />
              )}
            />
            <FormInput
              label="Email"
              disabled={!!influencerRequest}
              placeholder="Enter email"
              {...register('email')}
              error={errors.email?.message}
            />
            {!influencerRequest && (
              <>
                <FormInput
                  label="Password"
                  placeholder="Enter password"
                  {...register('password')}
                  error={errors.password?.message}
                  type="password"
                />
                <FormInput
                  label="Password confirmation"
                  placeholder="Enter password confirmation"
                  {...register('passwordConfirmation')}
                  error={errors.passwordConfirmation?.message}
                  type="password"
                />
              </>
            )}
            <Controller
              name="dateOfBirth"
              control={control}
              render={({ field }) => {
                return (
                  <FormDatePicker
                    label="Date of birth"
                    placeholder="Enter date of birth"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.dateOfBirth?.message}
                  />
                );
              }}
            />
          </ContainerLeftPart>
          <ContainerRightPart>
            <UploadRow>
              <Controller
                name="profilePhoto"
                control={control}
                render={({ field }) => {
                  return (
                    <FormFileInput
                      label="Profile photo"
                      value={field.value}
                      onChange={field.onChange}
                      type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
                      error={errors.profilePhoto?.message}
                    />
                  );
                }}
              />
              <RatioContainer>
                <FormNumericInput
                  label="Payment ratio"
                  {...register('paymentRatio', {
                    valueAsNumber: true,
                  })}
                  error={errors.paymentRatio?.message}
                />
                <FormNumericInput
                  label="Voice Response ratio"
                  {...register('voiceResponseRatio', {
                    valueAsNumber: true,
                  })}
                  error={errors.voiceResponseRatio?.message}
                />
              </RatioContainer>
            </UploadRow>
            <Controller
              name="businessConnection"
              control={control}
              render={({ field }) => (
                <FormSelect
                  placeholder="Select business connection"
                  label="Business Connection"
                  options={
                    influencerVariables
                      ? influencerVariables.businessConnections.map(value => ({ value, label: value.name }))
                      : []
                  }
                  selectedOption={field.value}
                  handleChange={value => field.onChange(value)}
                  error={errors.businessConnection?.message}
                />
              )}
            />
            <FormInput
              label="Telegram Channel URL"
              placeholder="Enter Telegram Channel URL"
              {...register('telegramChannelUrl')}
              error={errors.telegramChannelUrl?.message}
            />
            <Text16Regular>Voice Providers</Text16Regular>
            {watchVoiceProviders && (
              <ActiveProviderText>
                Active Provider: {VOICE_PROVIDER_TITLE[watchVoiceProviders.active.value]}
              </ActiveProviderText>
            )}
            <MainButton
              title="Set Voice Providers"
              type="button"
              onClick={() => handleVoiceProvidersModalRef.current.show()}
            />
            <ErrorContainer errorText={errors.voiceProviders?.message} />
            <FormNumericInput
              label="Dick Rate Price"
              {...register('dickRatePrice', {
                valueAsNumber: true,
              })}
              error={errors.dickRatePrice?.message}
            />
            <FormNumericInput
              label="Sexting Price"
              {...register('sextingPrice', {
                valueAsNumber: true,
              })}
              error={errors.sextingPrice?.message}
            />
            <FormNumericInput
              label="Lip Sync Price"
              {...register('lipSyncPrice', {
                valueAsNumber: true,
              })}
              error={errors.lipSyncPrice?.message}
            />
            <FormNumericInput
              label="Text On Body Price"
              {...register('textOnBodyPrice', {
                valueAsNumber: true,
              })}
              error={errors.textOnBodyPrice?.message}
            />
            <FormNumericInput
              label="Tipping Price"
              {...register('tippingPrice', {
                valueAsNumber: true,
              })}
              error={errors.tippingPrice?.message}
            />
          </ContainerRightPart>
        </Container>
      </CreateInfluencerForm>
      <Footer>
        <div />
        <FooterRightButton
          title="Next Step"
          icon={<ForwardIcon fill="#fff" />}
          onClick={() => handleSubmit(onSubmit)()}
        />
      </Footer>
      <HandleVoiceProvidersModal
        modalRef={handleVoiceProvidersModalRef}
        onSetVoiceProviders={data => setValue('voiceProviders', data, { shouldValidate: true })}
        voiceProviders={watchVoiceProviders}
      />
      <CropImageModal
        ref={cropImageRef}
        onClose={() => {
          setValue('profilePhoto', null);
          cropImageRef.current.hide();
        }}
        onComplete={croppedImage => {
          setValue('profilePhoto', croppedImage);
          cropImageRef.current.hide();
        }}
        originalImage={getValues('profilePhoto')}
      />
    </>
  );
}

export default CreateInfluencerPageStepBasicInfo;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AddToGalleryButton,
  AlbumMediaListContainer,
  PremiumMediaGroup,
  PremiumMediaGroupItemsContainer,
  PremiumMediaListContainer,
  Title,
} from './InfluencerGalleryTab.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetInfluencerAlbumMediaInfo,
  selectDeleteAlbumMediaPending,
  selectFetchInfluencerAlbumMediaInfo,
  selectFetchInfluencerVariablesInfoData,
  selectSelectedInfluencer,
  setInfluencerAlbumMedia,
} from '../../../../../store/slices/influencer/slice';
import {
  changeAlbumMediaPosition,
  deleteInfluencerAlbumMedia,
  fetchInfluencerAlbumMedia,
} from '../../../../../store/slices/influencer/asyncThunks';
import { capitalizeFirstLetter } from '../../../../../utils/util';
import { FlexHeader, FlexMain } from '../../../../../components/utils/utils.styles';
import { MEDIA_CATEGORY, MEDIA_TYPE } from '../../../../../utils/constants';
import AlbumMedia from '../../../../../components/AlbumMedia/AlbumMedia';
import UploadChannelVideoFormModal from '../../../../../components/modals/UploadChannelVideoFormModal/UploadChannelVideoFormModal';
import UploadPremiumVideoFormModal from '../../../../../components/modals/UploadPremiumVideoFormModal/UploadPremiumVideoFormModal';
import UploadChannelPhotoFormModal from '../../../../../components/modals/UploadChannelPhotoFormModal/UploadChannelPhotoFormModal';
import UploadPremiumPhotoFormModal from '../../../../../components/modals/UploadPremiumPhotoFormModal/UploadPremiumPhotoFormModal';
import { notifyError, notifyInfo } from '../../../../../utils/notify';
import MainLoader from '../../../../../components/utils/loaders/MainLoader/MainLoader';
import { useConfirmationModal } from '../../../../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import PreviewAlbumMediaModal from '../../../../../components/modals/PreviewAlbumMediaModal/PreviewAlbumMediaModal';
import { Text16Bold } from '../../../../../components/utils/texts/text.styles';

function InfluencerGalleryTab({ mediaType, mediaCategory }) {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const { data, pending } = useSelector(selectFetchInfluencerAlbumMediaInfo);
  const deleteAlbumMediaPending = useSelector(selectDeleteAlbumMediaPending);
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);
  const [selectedAlbumMedia, setSelectedAlbumMedia] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const uploadNewAlbumMediaRef = useRef();
  const previewAlbumMediaModalRef = useRef();
  const dispatch = useDispatch();
  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();

  useEffect(() => {
    dispatch(fetchInfluencerAlbumMedia({ influencerId: selectedInfluencer.id, mediaType, mediaCategory }));
    return () => {
      dispatch(resetInfluencerAlbumMediaInfo());
    };
  }, [dispatch, selectedInfluencer.id, mediaType, mediaCategory]);

  useEffect(() => {
    setConfirmationModalLoader(deleteAlbumMediaPending);
  }, [deleteAlbumMediaPending, setConfirmationModalLoader]);

  const UploadAlbumMediaFormModal = useMemo(() => {
    switch (mediaType) {
      case MEDIA_TYPE.PHOTO:
        if (mediaCategory === MEDIA_CATEGORY.CHANNEL) {
          return (
            <UploadChannelPhotoFormModal
              modalRef={uploadNewAlbumMediaRef}
              selectedAlbumMedia={selectedAlbumMedia}
              onAfterClose={() => setSelectedAlbumMedia(null)}
            />
          );
        } else {
          return (
            <UploadPremiumPhotoFormModal
              modalRef={uploadNewAlbumMediaRef}
              selectedAlbumMedia={selectedAlbumMedia}
              onAfterClose={() => setSelectedAlbumMedia(null)}
            />
          );
        }
      case MEDIA_TYPE.VIDEO:
        if (mediaCategory === MEDIA_CATEGORY.CHANNEL) {
          return (
            <UploadChannelVideoFormModal
              modalRef={uploadNewAlbumMediaRef}
              selectedAlbumMedia={selectedAlbumMedia}
              onAfterClose={() => setSelectedAlbumMedia(null)}
            />
          );
        } else {
          return (
            <UploadPremiumVideoFormModal
              modalRef={uploadNewAlbumMediaRef}
              selectedAlbumMedia={selectedAlbumMedia}
              onAfterClose={() => setSelectedAlbumMedia(null)}
            />
          );
        }

      default:
        return null;
    }
  }, [mediaType, mediaCategory, selectedAlbumMedia]);

  const onAddNewAlbumMedia = () => {
    uploadNewAlbumMediaRef.current.show();
  };

  const onEditAlbumMedia = useCallback(albumMedia => {
    setSelectedAlbumMedia(albumMedia);
    uploadNewAlbumMediaRef.current.show();
  }, []);

  const movePhoto = (fromIndex, toIndex, key) => {
    const oldData = [...data[key]];
    const updatedMedia = [...data[key]];
    const [movedMedia] = updatedMedia.splice(fromIndex, 1);
    updatedMedia.splice(toIndex, 0, movedMedia);

    const mediaPositions = updatedMedia.map((media, index) => ({ id: media.id, position: index + 1 }));
    dispatch(setInfluencerAlbumMedia({ updatedMedia, key }));
    dispatch(changeAlbumMediaPosition({ influencerId: selectedInfluencer.id, mediaType, mediaPositions }))
      .unwrap()
      .then(() => {})
      .catch(err => {
        dispatch(setInfluencerAlbumMedia({ updatedMedia: oldData, key }));
        notifyError(err.message);
      });
  };

  const onDragging = (fromIndex, toIndex, key) => {
    const updatedMedia = [...data[key]];
    const [movedMedia] = updatedMedia.splice(fromIndex, 1);
    updatedMedia.splice(toIndex, 0, movedMedia);
    dispatch(setInfluencerAlbumMedia({ updatedMedia, key }));
  };

  const onDeleteAlbumMediaMedia = (event, albumMediaId, mediaType, mediaCategory, mediaTag) => {
    openModal(
      'Delete Album Media',
      `Are you sure you want to delete album media?`,
      'Delete',
      () => {
        dispatch(deleteInfluencerAlbumMedia({ albumMediaId, mediaType, mediaCategory, mediaTag }))
          .unwrap()
          .then(() => {
            notifyInfo('Album media deleted successfully!');
            closeModal();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
    event.stopPropagation();
  };

  const onAlbumMediaClick = albumMedia => {
    setSelectedUrl(albumMedia.url);
    previewAlbumMediaModalRef.current.show();
  };

  const premiumTagGroupTitle = key => {
    if (mediaCategory === MEDIA_CATEGORY.PREMIUM && influencerVariables?.albumMediaTags) {
      const foundTag = influencerVariables.albumMediaTags.find(tag => tag.id === key);
      return foundTag ? foundTag.name : key;
    }
  };

  return (
    <>
      <FlexHeader>
        <Title>
          {capitalizeFirstLetter(mediaCategory)} {capitalizeFirstLetter(mediaType)} Gallery
        </Title>
        <AddToGalleryButton title="Add media" onClick={() => onAddNewAlbumMedia()} />
      </FlexHeader>
      <FlexMain>
        {pending ? (
          <MainLoader />
        ) : mediaCategory === MEDIA_CATEGORY.CHANNEL ? (
          <AlbumMediaListContainer>
            {data.map((item, i) => (
              <AlbumMedia
                disableDragAndDrop
                sectionKey={mediaCategory}
                key={item.id ? item.id : i}
                index={i}
                src={item.url}
                mediaType={mediaType}
                onClick={() => onAlbumMediaClick(item)}
                item={item}
                alt="album-media-item"
                onEdit={() => onEditAlbumMedia(item)}
                onDelete={event => onDeleteAlbumMediaMedia(event, item.id, mediaType, mediaCategory)}
              />
            ))}
          </AlbumMediaListContainer>
        ) : (
          <PremiumMediaListContainer>
            {Object.entries(data).map(([key, value]) => {
              return (
                !!value.length && (
                  <PremiumMediaGroup key={key}>
                    <Text16Bold>{premiumTagGroupTitle(key)}</Text16Bold>
                    <PremiumMediaGroupItemsContainer>
                      {value.map((item, i) => (
                        <AlbumMedia
                          key={item.id ? item.id : i}
                          index={i}
                          sectionKey={key}
                          src={item.url}
                          mediaType={mediaType}
                          onClick={() => onAlbumMediaClick(item)}
                          item={item}
                          alt="album-media-item"
                          movePhoto={(from, to) => movePhoto(from, to, key)}
                          onDragging={(from, to) => onDragging(from, to, key)}
                          onEdit={() => onEditAlbumMedia(item)}
                          onDelete={event =>
                            onDeleteAlbumMediaMedia(event, item.id, mediaType, mediaCategory, item.tag)
                          }
                        />
                      ))}
                    </PremiumMediaGroupItemsContainer>
                  </PremiumMediaGroup>
                )
              );
            })}
          </PremiumMediaListContainer>
        )}
      </FlexMain>
      {UploadAlbumMediaFormModal}
      <PreviewAlbumMediaModal
        modalRef={previewAlbumMediaModalRef}
        type={mediaType}
        url={selectedUrl}
        setSelectedUrl={setSelectedUrl}
      />
    </>
  );
}

export default InfluencerGalleryTab;

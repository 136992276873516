import React, { useEffect, useRef } from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormInput, FormNumericInput } from '../../../forms/form.styles';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { Text16Regular } from '../../utils/texts/text.styles';
import {
  DeleteIconWrapper,
  MediaCardContainer,
  SelectedMediaContainer,
  SelectMedia,
  SelectMediaContainer,
} from './CreateMediaBundleFormModal.styles';
import ErrorContainer from '../../utils/ErrorContainer/ErrorContainer';
import SelectMediaModal from '../SelectMediaModal/SelectMediaModal';
import { createMediaBundleFormSchema } from '../../../forms/schemas/albumMedia.schema';
import Video from '../../utils/Video/Video';
import Image from '../../utils/Image/Image';
import { MEDIA_TYPE } from '../../../utils/constants';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import {
  selectCreateMediaBundlePending,
  selectFetchMediaBundleByIdPending,
} from '../../../store/slices/influencer/slice';
import { createMediaBundle, fetchMediaBundleById } from '../../../store/slices/influencer/asyncThunks';
import MainLoader from '../../utils/loaders/MainLoader/MainLoader';

const CreateMediaBundleFormModal = ({ modalRef, onSuccess, selectedMediaBundle, setSelectedMediaBundle }) => {
  const selectMediaModalRef = useRef();
  const createMediaBundlePending = useSelector(selectCreateMediaBundlePending);
  const fetchMediaBundleByIdPending = useSelector(selectFetchMediaBundleByIdPending);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      media: [],
      caption: '',
      aiDescription: '',
      sale: '',
    },
    delayError: 300,
    resolver: yupResolver(createMediaBundleFormSchema),
  });

  const mediaWatch = watch('media');

  useEffect(() => {
    if (selectedMediaBundle) {
      dispatch(fetchMediaBundleById({ mediaBundleId: selectedMediaBundle }))
        .unwrap()
        .then(bundle => {
          setValue('media', bundle.media);
          setValue('caption', bundle.caption);
          setValue('aiDescription', bundle.aiDescription);
          setValue('sale', bundle.sale);
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  }, [selectedMediaBundle, dispatch, setValue]);

  const onSubmit = data => {
    const mediaData = data.media.map(media => {
      return {
        mediaId: media.id,
        type: media.type,
      };
    });

    dispatch(
      createMediaBundle({
        sale: data.sale,
        caption: data.caption,
        aiDescription: data.aiDescription,
        media: mediaData,
      }),
    )
      .unwrap()
      .then(() => {
        reset();
        modalRef.current.hide();
        notifyInfo('Bundle created successfully!');
        onSuccess();
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  const onSelectMediaClick = () => {
    selectMediaModalRef.current.show();
  };

  const onDeleteMedia = mediaId => {
    let data = [...mediaWatch];
    data = data.filter(media => media.id !== mediaId);
    setValue('media', data);
  };

  return (
    <FormModal
      ref={modalRef}
      title={selectedMediaBundle ? 'Media Bundle' : 'Create Bundle'}
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        setSelectedMediaBundle(null);
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText={selectedMediaBundle ? 'Save' : 'Create'}
      {...(!selectedMediaBundle && { onRightButtonClick: () => handleSubmit(onSubmit)() })}
      rightButtonLoader={createMediaBundlePending}
      shouldCloseOnOverlayClick={false}>
      {fetchMediaBundleByIdPending ? (
        <MainLoader />
      ) : (
        <form>
          <SelectMediaContainer>
            <Text16Regular>Media</Text16Regular>
            {!selectedMediaBundle && <SelectMedia onClick={onSelectMediaClick}>Select Media</SelectMedia>}
          </SelectMediaContainer>
          <SelectedMediaContainer>
            {mediaWatch.map(item => {
              return (
                <MediaCard
                  key={item.id}
                  item={item}
                  type={item.type}
                  {...(!selectedMediaBundle && { onDeleteClick: () => onDeleteMedia(item.id) })}
                />
              );
            })}
          </SelectedMediaContainer>
          <ErrorContainer errorText={errors.media?.message} />
          <FormInput
            label="AI description"
            placeholder="Enter AI description"
            {...register('aiDescription')}
            disabled={selectedMediaBundle}
            error={errors.aiDescription?.message}
          />
          <FormInput
            label="Caption"
            placeholder="Enter caption"
            {...register('caption')}
            disabled={selectedMediaBundle}
            error={errors.caption?.message}
          />
          <FormNumericInput
            label="Sale"
            {...register('sale', {
              valueAsNumber: true,
            })}
            disabled={selectedMediaBundle}
            error={errors.sale?.message}
          />
        </form>
      )}
      <SelectMediaModal
        modalRef={selectMediaModalRef}
        onSelect={media => setValue('media', [...mediaWatch, ...media], { shouldValidate: true })}
        chosenMedia={mediaWatch}
      />
    </FormModal>
  );
};

const MediaCard = ({ item, type, onDeleteClick }) => {
  return (
    <MediaCardContainer>
      {onDeleteClick && (
        <DeleteIconWrapper onClick={onDeleteClick}>
          <DeleteIcon width={20} height={20} fill="white" />
        </DeleteIconWrapper>
      )}
      {type === MEDIA_TYPE.PHOTO ? (
        <Image src={item.url} alt="selected-premium-photo-media" />
      ) : (
        <Video src={item.url} />
      )}
    </MediaCardContainer>
  );
};

export default CreateMediaBundleFormModal;

import * as yup from 'yup';

const promptBaseFormSchema = yup
  .object()
  .shape({
    mainMessage: yup.object().shape({
      base: yup.object().shape({
        template: yup.string().trim().required('Prompt template is required'),
      }),
      sexting: yup.object().shape({
        template: yup.string().trim().required('Sexting Prompt template is required'),
      }),
    }),
    voiceCall: yup.object().shape({
      template: yup.string().when('$isVoiceCallEnabled', {
        is: true,
        then: schema => schema.trim().required('Voice call prompt template is required'),
        otherwise: schema => schema.notRequired(),
      }),
    }),
  })
  .required();

export const editPromptsFormSchema = yup
  .object({
    isVoiceCallEnabled: yup.boolean().required(),
    prompt: yup.object().shape({
      male: promptBaseFormSchema,
    }),
  })
  .required();

export const createPromptsAsAdminFormSchema = yup
  .object({
    prompt: yup.object().shape({
      male: promptBaseFormSchema,
    }),
  })
  .required();

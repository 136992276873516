import styled from 'styled-components';
import { MainButton } from '../../../../components/utils/buttons/Button/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StyledButton = styled(MainButton)`
  width: 20rem;
`;

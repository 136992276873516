import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { createInfluencerStepAlbumMediaFormSchema } from '../../../../../forms/schemas/influencer.schema';
import { CreateInfluencerForm, Footer, FooterLeftButton, FooterRightButton } from '../../CreateInfluencerPage.styles';
import { useConfirmationModal } from '../../../../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import {
  AddNewAlbumMediumButton,
  Container,
  ContainerFooter,
  ContainerHeader,
  ContentContainer,
  EmptyListContainer,
  Row,
} from './CreateInfluencerPageStepAlbumMedia.styles';
import ErrorContainer from '../../../../../components/utils/ErrorContainer/ErrorContainer';
import { ReactComponent as GalleryIcon } from '../../../../../assets/icons/gallery.svg';
import { Text20Bold } from '../../../../../components/utils/texts/text.styles';
import AlbumMedia from '../../../../../components/AlbumMedia/AlbumMedia';
import { MEDIA_CATEGORY, MEDIA_TYPE } from '../../../../../utils/constants';
import UploadChannelPhotoFormModal from '../../../../../components/modals/UploadChannelPhotoFormModal/UploadChannelPhotoFormModal';
import UploadPremiumPhotoFormModal from '../../../../../components/modals/UploadPremiumPhotoFormModal/UploadPremiumPhotoFormModal';
import UploadChannelVideoFormModal from '../../../../../components/modals/UploadChannelVideoFormModal/UploadChannelVideoFormModal';
import UploadPremiumVideoFormModal from '../../../../../components/modals/UploadPremiumVideoFormModal/UploadPremiumVideoFormModal';
import { useSelector } from 'react-redux';
import { selectCreateInfluencerPending } from '../../../../../store/slices/influencer/slice';

function CreateInfluencerPageStepAlbumMedia({ step, onAfterSubmit, onBack, formData }) {
  const createInfluencerPending = useSelector(selectCreateInfluencerPending);
  const [selectedAlbumMedium, setSelectedAlbumMedium] = useState(null);
  const [openModal, closeModal] = useConfirmationModal();
  const channelPhotosModalRef = useRef();
  const premiumPhotosModalRef = useRef();
  const channelVideosModalRef = useRef();
  const premiumVideosModalRef = useRef();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      channelPhotos: [],
      premiumPhotos: [],
      channelVideos: [],
      premiumVideos: [],
    },
    delayError: 300,
    resolver: yupResolver(createInfluencerStepAlbumMediaFormSchema),
  });

  const {
    fields: channelPhotosFields,
    append: appendChannelPhotos,
    remove: removeChannelPhotos,
    update: updateChannelPhotos,
  } = useFieldArray({
    control,
    name: 'channelPhotos',
  });

  const {
    fields: premiumPhotosFields,
    append: appendPremiumPhotos,
    remove: removePremiumPhotos,
    update: updatePremiumPhotos,
  } = useFieldArray({
    control,
    name: 'premiumPhotos',
  });

  const {
    fields: channelVideosFields,
    append: appendChannelVideos,
    remove: removeChannelVideos,
    update: updateChannelVideos,
  } = useFieldArray({
    control,
    name: 'channelVideos',
  });

  const {
    fields: premiumVideosFields,
    append: appendPremiumVideos,
    remove: removePremiumVideos,
    update: updatePremiumVideos,
  } = useFieldArray({
    control,
    name: 'premiumVideos',
  });

  useEffect(() => {
    if (formData.albumMedia) {
      if (formData.albumMedia.channelPhotos.length) {
        for (const channelPhoto of formData.albumMedia.channelPhotos) {
          appendChannelPhotos(channelPhoto);
        }
      }
      if (formData.albumMedia.premiumPhotos.length) {
        for (const premiumPhoto of formData.albumMedia.premiumPhotos) {
          appendPremiumPhotos(premiumPhoto);
        }
      }
      if (formData.albumMedia.channelVideos.length) {
        for (const channelVideo of formData.albumMedia.channelVideos) {
          appendChannelVideos(channelVideo);
        }
      }
      if (formData.albumMedia.premiumVideos.length) {
        for (const premiumVideo of formData.albumMedia.premiumVideos) {
          appendPremiumVideos(premiumVideo);
        }
      }
    }
  }, [
    formData.albumMedia,
    setValue,
    appendChannelPhotos,
    appendPremiumPhotos,
    appendChannelVideos,
    appendPremiumVideos,
  ]);

  const onEditAlbumMedia = useCallback((albumMedia, ref) => {
    setSelectedAlbumMedium(albumMedia);
    ref.current.show();
  }, []);

  const onDeleteAlbumMedia = useCallback(
    (event, mediaType, mediaCategory, index) => {
      openModal(
        'Delete album media',
        `Are you sure you want to delete album media?`,
        'Delete',
        () => {
          switch (mediaType) {
            case MEDIA_TYPE.PHOTO:
              if (mediaCategory === MEDIA_CATEGORY.CHANNEL) {
                removeChannelPhotos(index);
              } else {
                removePremiumPhotos(index);
              }
              break;
            case MEDIA_TYPE.VIDEO:
              if (mediaCategory === MEDIA_CATEGORY.CHANNEL) {
                removeChannelVideos(index);
              } else {
                removePremiumVideos(index);
              }
              break;
            default:
              break;
          }
          closeModal();
        },
        'Cancel',
        () => {
          closeModal();
        },
      );
      event.stopPropagation();
    },
    [openModal, closeModal, removeChannelPhotos, removePremiumPhotos, removeChannelVideos, removePremiumVideos],
  );

  const onSubmit = data => {
    onAfterSubmit(data, step);
  };

  const refs = useMemo(() => {
    return {
      [`${MEDIA_CATEGORY.CHANNEL}-${MEDIA_TYPE.PHOTO}`]: { ref: channelPhotosModalRef },
      [`${MEDIA_CATEGORY.PREMIUM}-${MEDIA_TYPE.PHOTO}`]: { ref: premiumPhotosModalRef },
      [`${MEDIA_CATEGORY.CHANNEL}-${MEDIA_TYPE.VIDEO}`]: { ref: channelVideosModalRef },
      [`${MEDIA_CATEGORY.PREMIUM}-${MEDIA_TYPE.VIDEO}`]: { ref: premiumVideosModalRef },
    };
  }, []);

  const renderAlbumMediaContainer = (
    title,
    modalRef,
    fieldsList,
    alt,
    errorMessage,
    mediaType,
    mediaCategory,
    disableAddNewAlbumMedia,
  ) => {
    return (
      <Container>
        <ContainerHeader>
          <Row>
            <GalleryIcon />
            <Text20Bold>{title}</Text20Bold>
          </Row>
          <AddNewAlbumMediumButton
            title="Add media"
            type="button"
            onClick={() => modalRef.current.show()}
            disabled={disableAddNewAlbumMedia}
          />
        </ContainerHeader>
        {fieldsList.length ? (
          <>
            {fieldsList.map((item, index) => {
              return (
                <AlbumMedia
                  disableDragAndDrop
                  key={item.id}
                  id={index}
                  sectionKey={mediaCategory}
                  src={typeof item.albumMedia === 'string' ? item.albumMedia : item.albumMedia.content}
                  mediaType={mediaType}
                  index={index}
                  item={item}
                  onClick={onEditAlbumMedia}
                  onClickParams={refs[`${mediaCategory}-${mediaType}`]}
                  onDelete={e => onDeleteAlbumMedia(e, mediaType, mediaCategory, index)}
                  alt={alt}
                />
              );
            })}
          </>
        ) : (
          <EmptyListContainer />
        )}
        <ContainerFooter>
          <ErrorContainer errorText={errorMessage} />
        </ContainerFooter>
      </Container>
    );
  };

  return (
    <>
      <CreateInfluencerForm>
        <ContentContainer>
          {renderAlbumMediaContainer(
            'Channel Photos',
            channelPhotosModalRef,
            channelPhotosFields,
            'channel-photo-item',
            errors.channelPhotos?.message,
            MEDIA_TYPE.PHOTO,
            MEDIA_CATEGORY.CHANNEL,
            channelPhotosFields.length > 4,
          )}
          {renderAlbumMediaContainer(
            'Premium Photos',
            premiumPhotosModalRef,
            premiumPhotosFields,
            'premium-photo-item',
            errors.premiumPhotos?.message,
            MEDIA_TYPE.PHOTO,
            MEDIA_CATEGORY.PREMIUM,
            premiumPhotosFields.length > 4,
          )}
          {renderAlbumMediaContainer(
            'Channel Videos',
            channelVideosModalRef,
            channelVideosFields,
            'channel-video-item',
            errors.channelVideos?.message,
            MEDIA_TYPE.VIDEO,
            MEDIA_CATEGORY.CHANNEL,
            channelVideosFields.length > 4,
          )}
          {renderAlbumMediaContainer(
            'Premium Videos',
            premiumVideosModalRef,
            premiumVideosFields,
            'premium-video-item',
            errors.premiumVideos?.message,
            MEDIA_TYPE.VIDEO,
            MEDIA_CATEGORY.PREMIUM,
            premiumVideosFields.length > 4,
          )}
        </ContentContainer>
      </CreateInfluencerForm>
      <Footer>
        <FooterLeftButton title="Previous Step" onClick={() => onBack(getValues(), step)} />
        <FooterRightButton
          title="Create Influencer"
          onClick={() => handleSubmit(onSubmit)()}
          isLoading={createInfluencerPending}
        />
      </Footer>
      <UploadChannelPhotoFormModal
        modalRef={channelPhotosModalRef}
        selectedAlbumMedia={selectedAlbumMedium}
        customOnSubmit={
          selectedAlbumMedium
            ? data => {
                const index = channelPhotosFields.findIndex(albumMedia => albumMedia.id === selectedAlbumMedium.id);
                updateChannelPhotos(index, { ...channelPhotosFields[index], ...data });
              }
            : data => appendChannelPhotos(data)
        }
        onAfterClose={() => setSelectedAlbumMedium(null)}
      />
      <UploadPremiumPhotoFormModal
        modalRef={premiumPhotosModalRef}
        selectedAlbumMedia={selectedAlbumMedium}
        customOnSubmit={
          selectedAlbumMedium
            ? data => {
                const index = premiumPhotosFields.findIndex(albumMedia => albumMedia.id === selectedAlbumMedium.id);
                updatePremiumPhotos(index, { ...premiumPhotosFields[index], ...data });
              }
            : data => appendPremiumPhotos(data)
        }
        onAfterClose={() => setSelectedAlbumMedium(null)}
      />
      <UploadChannelVideoFormModal
        modalRef={channelVideosModalRef}
        selectedAlbumMedia={selectedAlbumMedium}
        customOnSubmit={
          selectedAlbumMedium
            ? data => {
                const index = channelVideosFields.findIndex(albumMedia => albumMedia.id === selectedAlbumMedium.id);
                updateChannelVideos(index, { ...channelVideosFields[index], ...data });
              }
            : data => appendChannelVideos(data)
        }
        onAfterClose={() => setSelectedAlbumMedium(null)}
      />
      <UploadPremiumVideoFormModal
        modalRef={premiumVideosModalRef}
        selectedAlbumMedia={selectedAlbumMedium}
        customOnSubmit={
          selectedAlbumMedium
            ? data => {
                const index = premiumVideosFields.findIndex(albumMedia => albumMedia.id === selectedAlbumMedium.id);
                updatePremiumVideos(index, { ...premiumVideosFields[index], ...data });
              }
            : data => appendPremiumVideos(data)
        }
        onAfterClose={() => setSelectedAlbumMedium(null)}
      />
    </>
  );
}

export default CreateInfluencerPageStepAlbumMedia;

import * as yup from 'yup';
import { uploadChannelAlbumMediumFormSchema, uploadPremiumAlbumMediaFormSchema } from './albumMedia.schema';
import { VOICE_PROVIDER } from '../../utils/constants';

export const createInfluencerStepBasicInfoFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required').max(255, 'Max length reached'),
    nickname: yup.string().trim().required('Nickname is required').max(255, 'Max length reached'),
    username: yup.string().trim().required('Username is required').max(255, 'Max length reached'),
    email: yup.string().trim().required('Email is required').email('Invalid email'),
    password: yup.string().when('influencerRequestId', {
      is: influencerRequestId => !influencerRequestId,
      then: () =>
        yup.string().trim().required('Password is required').min(6, 'Password must have at least 6 characters'),
    }),
    passwordConfirmation: yup.string().when('influencerRequestId', {
      is: influencerRequestId => !influencerRequestId,
      then: () =>
        yup
          .string()
          .trim()
          .required('Password confirmation is required')
          .oneOf([yup.ref('password'), ''], "Passwords don't match!"),
    }),
    dateOfBirth: yup
      .date()
      .required('Birth of date is required')
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Influencer must be 18+'),
    profilePhoto: yup.mixed().required('Profile photo is required'),
    businessConnection: yup.mixed().required('Business connection is required'),
    paymentRatio: yup
      .number()
      .typeError('Payment ratio must be a number')
      .required('Payment ratio is required')
      .min(0, 'Payment ratio cannot be below 0')
      .max(1, 'Payment ratio cannot be above 1'),
    voiceResponseRatio: yup
      .number()
      .typeError('Voice response ratio must be a number')
      .required('Voice response ratio is required')
      .min(0, 'Voice response ratio cannot be below 0')
      .max(1, 'Voice response ratio cannot be above 1'),
    dickRatePrice: yup
      .number()
      .typeError('Dick rate price must be a number')
      .required('Dick rate price is required')
      .min(0.001, 'Dick rate price must be above 0'),
    sextingPrice: yup
      .number()
      .typeError('Sexting price must be a number')
      .required('Sexting price is required')
      .min(0.001, 'Sexting price must be above 0'),
    lipSyncPrice: yup
      .number()
      .typeError('Lip sync price must be a number')
      .required('Lip sync price is required')
      .min(0.001, 'Lip sync price must be above 0'),
    textOnBodyPrice: yup
      .number()
      .typeError('Text on body price must be a number')
      .required('Text on body price is required')
      .min(0.001, 'Text on body price must be above 0'),
    tippingPrice: yup
      .number()
      .typeError('Tipping price must be a number')
      .required('Tipping price is required')
      .min(0.001, 'Tipping price must be above 0'),
    voiceProviders: yup.mixed().required('Voice providers are required'),
  })
  .required();

export const createInfluencerStepAlbumMediaFormSchema = yup
  .object({
    channelPhotos: yup.array().of(uploadChannelAlbumMediumFormSchema),
    premiumPhotos: yup.array().of(uploadPremiumAlbumMediaFormSchema).min(1, 'At least one premium photo is required'),
    channelVideos: yup.array().of(uploadChannelAlbumMediumFormSchema),
    premiumVideos: yup.array().of(uploadPremiumAlbumMediaFormSchema).min(1, 'At least one premium video is required'),
  })
  .required();

export const editInfluencerFormSchema = yup
  .object({
    profilePhoto: yup.mixed().required('Profile photo is required'),
    name: yup.string().trim().required('Name is required').max(255, 'Max length reached'),
    nickname: yup.string().trim().required('Nickname is required').max(255, 'Max length reached'),
    email: yup.string().trim().required('Email is required').email('Invalid email'),
    dateOfBirth: yup
      .date()
      .required('Birth of date is required')
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Influencer must be 18+'),
    paymentRatio: yup
      .number()
      .typeError('Payment ratio must be a number')
      .required('Payment ratio is required')
      .min(0, 'Payment ratio cannot be below 0')
      .max(1, 'Payment ratio cannot be above 1'),
    voiceResponseRatio: yup
      .number()
      .typeError('Voice response ratio must be a number')
      .required('Voice response ratio is required')
      .min(0, 'Voice response ratio cannot be below 0')
      .max(1, 'Voice response ratio cannot be above 1'),
    dickRatePrice: yup
      .number()
      .typeError('Dick rate price must be a number')
      .required('Dick rate price is required')
      .min(0.001, 'Dick rate price must be above 0'),
    sextingPrice: yup
      .number()
      .typeError('Sexting price must be a number')
      .required('Sexting price is required')
      .min(0.001, 'Sexting price must be above 0'),
    lipSyncPrice: yup
      .number()
      .typeError('Lip sync price must be a number')
      .required('Lip sync price is required')
      .min(0.001, 'Lip sync price must be above 0'),
    textOnBodyPrice: yup
      .number()
      .typeError('Text on body price must be a number')
      .required('Text on body price is required')
      .min(0.001, 'Text on body price must be above 0'),
    tippingPrice: yup
      .number()
      .typeError('Tipping price must be a number')
      .required('Tipping price is required')
      .min(0.001, 'Tipping price must be above 0'),
    voiceProviders: yup.mixed().required('Voice providers are required'),
  })
  .required();

export const addVoiceProviderFormSchema = yup
  .object({
    active: yup.mixed().required('Please select a voice provider'),
    cartesia: yup.object().when('active', {
      is: ({ value }) => {
        return value === VOICE_PROVIDER.CARTESIA;
      },
      then: () =>
        yup
          .object({
            voiceId: yup.string().trim().required('Voice ID is required'),
          })
          .required(),
      otherwise: () => yup.object().notRequired(),
    }),
    elevenLabs: yup.object().when('active', {
      is: ({ value }) => {
        return value === VOICE_PROVIDER.ELEVEN_LABS;
      },
      then: () =>
        yup
          .object({
            voiceId: yup.string().trim().required('Voice ID is required'),
            voiceConfig: yup.object({
              stability: yup
                .number()
                .typeError('Stability must be a number')
                .min(0, 'Stability must be number between 0 and 1')
                .max(1, 'Stability must be number between 0 and 1')
                .required('Stability is required'),
              similarityBoost: yup
                .number()
                .typeError('Similarity boost must be a number')
                .min(0, 'Similarity boost must be number between 0 and 1')
                .max(1, 'Similarity boost must be number between 0 and 1')
                .required('Similarity boost is required'),
              style: yup
                .number()
                .typeError('Style must be a number')
                .min(0, 'Style must be number between 0 and 1')
                .max(1, 'Style must be number between 0 and 1')
                .required('Style is required'),
            }),
          })
          .required(),
      otherwise: () => yup.object().notRequired(),
    }),
  })
  .required();

import styled from 'styled-components';
import { Text16Bold } from '../../utils/texts/text.styles';

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
`;

export const PriceInUsd = styled(Text16Bold)`
  width: 50%;
  margin-bottom: 3.8rem;
`;

import * as yup from 'yup';

export const uploadChannelAlbumMediumFormSchema = yup
  .object({
    albumMedia: yup.mixed().required('Album media is required'),
    caption: yup.string().trim().required('Caption is required'),
    fileId: yup.string().trim().required('File ID is required'),
  })
  .required();

export const uploadPremiumAlbumMediaFormSchema = yup
  .object({
    albumMedia: yup.mixed().required('Album media is required'),
    cost: yup
      .number()
      .integer()
      .typeError('Cost must be a whole number')
      .required('Cost is required')
      .positive('Cost must be positive'),
    aiDescription: yup.string().trim().required('AI Description is required'),
    caption: yup.string().trim().required('Caption is required'),
    fileId: yup.string().trim().required('File ID is required'),
    tag: yup.mixed().required('Please select tag'),
  })
  .required();

export const createMediaBundleFormSchema = yup
  .object({
    caption: yup.string().trim().required('Caption is required'),
    sale: yup
      .number()
      .integer()
      .typeError('Sale must be a whole number')
      .required('Sale is required')
      .min(0, 'Sale must be positive number')
      .max(100, 'Sale must be less than 100'),
    media: yup
      .array()
      .min(2, 'At least 2 media items are required')
      .max(10, 'Maximum of 10 media items are allowed')
      .of(
        yup.object({
          id: yup.string().required(),
          url: yup.string().required(),
          type: yup.string().required(),
        }),
      ),
    aiDescription: yup.string().trim().required('AI Description is required'),
  })
  .required();

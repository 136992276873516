import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditInfluencerPending, selectSelectedInfluencer } from '../../../../store/slices/influencer/slice';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActiveProviderText, EditInfluencerButton } from './InfluencerEditTab.styles';
import { UPLOAD_FILE_CONSTRAINTS, VOICE_PROVIDER_TITLE } from '../../../../utils/constants';
import { editInfluencer } from '../../../../store/slices/influencer/asyncThunks';
import { notifyError, notifyInfo } from '../../../../utils/notify';
import { FlexFooter, FlexMain } from '../../../../components/utils/utils.styles';
import { editInfluencerFormSchema } from '../../../../forms/schemas/influencer.schema';
import { FormDatePicker, FormFileInput, FormInput, FormNumericInput } from '../../../../forms/form.styles';
import CropImageModal from '../../../../components/utils/modals/CropImageModal/CropImageModal';
import HandleVoiceProvidersModal from '../../../../components/modals/HandleVoiceProvidersModal/HandleVoiceProvidersModal';
import ErrorContainer from '../../../../components/utils/ErrorContainer/ErrorContainer';
import { Text16Regular } from '../../../../components/utils/texts/text.styles';
import { SetVoiceProvidersButton } from '../../CreateInfluencerPage/steps/CreateInfluencerPageStepBasicInfo/CreateInfluencerPageStepBasicInfo.styles';

function InfluencerEditTab() {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const editInfluencerPending = useSelector(selectEditInfluencerPending);
  const cropImageRef = useRef();
  const handleVoiceProvidersModalRef = useRef();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      profilePhoto: selectedInfluencer.profilePhoto || null,
      name: selectedInfluencer.name || '',
      nickname: selectedInfluencer.nickname || '',
      email: selectedInfluencer.email || '',
      dateOfBirth: selectedInfluencer.dateOfBirth || null,
      paymentRatio: selectedInfluencer.paymentRatio || 0.5,
      voiceResponseRatio: !isNaN(selectedInfluencer.voiceResponseRatio) ? selectedInfluencer.voiceResponseRatio : 0.5,
      telegramChannelUrl: selectedInfluencer.telegram.channelUrl || '',
      dickRatePrice: selectedInfluencer.pricing.dickRate || 20,
      sextingPrice: selectedInfluencer.pricing.sexting || 20,
      lipSyncPrice: selectedInfluencer.pricing.lipSync || 20,
      tippingPrice: selectedInfluencer.pricing.tipping || 299,
      textOnBodyPrice: selectedInfluencer.pricing.textOnBody || 20,
      voiceProviders: null,
    },
    delayError: 300,
    resolver: yupResolver(editInfluencerFormSchema),
  });

  const watchProfilePhoto = watch('profilePhoto');
  const watchVoiceProviders = watch('voiceProviders');

  useEffect(() => {
    if (selectedInfluencer) {
      const voiceProvider = selectedInfluencer.voiceProvider;
      setValue('voiceProviders.active', {
        value: voiceProvider.active,
        label: VOICE_PROVIDER_TITLE[voiceProvider.active],
      });
      if (voiceProvider.cartesia) {
        setValue('voiceProviders.cartesia', voiceProvider.cartesia);
      } else {
        setValue('voiceProviders.cartesia', { voiceId: '' });
      }
      if (voiceProvider.elevenLabs) {
        setValue('voiceProviders.elevenLabs', voiceProvider.elevenLabs);
      } else {
        setValue('voiceProviders.elevenLabs', {
          voiceId: '',
          voiceConfig: { stability: '0.35', similarityBoost: '0.75', style: '0.49', useSpeakerBoost: true },
        });
      }
    }
  }, [selectedInfluencer, setValue]);

  useEffect(() => {
    if (watchProfilePhoto) {
      if (typeof watchProfilePhoto !== 'string') {
        cropImageRef.current.show();
      }
    }
  }, [watchProfilePhoto]);

  const onSubmit = data => {
    dispatch(editInfluencer(data))
      .unwrap()
      .then(_ => {
        notifyInfo('Influencer updated successfully!');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <>
      <FlexMain>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="profilePhoto"
            control={control}
            render={({ field }) => {
              return (
                <FormFileInput
                  label="Profile photo"
                  value={field.value}
                  onChange={field.onChange}
                  type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
                  error={errors.profilePhoto?.message}
                />
              );
            }}
          />
          <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
          <FormInput
            label="Nickname"
            placeholder="Enter nickname"
            {...register('nickname')}
            error={errors.nickname?.message}
          />
          <FormInput label="Email" placeholder="Enter email" {...register('email')} error={errors.email?.message} />
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => {
              return (
                <FormDatePicker
                  label="Date of birth"
                  placeholder="Enter date of birth"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.dateOfBirth?.message}
                />
              );
            }}
          />
          <FormInput
            label="Telegram Channel URL"
            placeholder="Enter Telegram Channel URL"
            {...register('telegramChannelUrl')}
            error={errors.telegramChannelUrl?.message}
          />
          <Text16Regular>Voice Providers</Text16Regular>
          {watchVoiceProviders && (
            <ActiveProviderText>
              Active Provider: {VOICE_PROVIDER_TITLE[watchVoiceProviders.active.value]}
            </ActiveProviderText>
          )}
          <SetVoiceProvidersButton
            title="Set Voice Providers"
            type="button"
            onClick={() => handleVoiceProvidersModalRef.current.show()}
          />
          <ErrorContainer errorText={errors.voiceProviders?.message} />
          <FormNumericInput
            label="Payment ratio"
            {...register('paymentRatio', {
              valueAsNumber: true,
            })}
            error={errors.paymentRatio?.message}
          />
          <FormNumericInput
            label="Voice response ratio"
            {...register('voiceResponseRatio', {
              valueAsNumber: true,
            })}
            error={errors.voiceResponseRatio?.message}
          />
          <FormNumericInput
            label="Dick Rate Price"
            {...register('dickRatePrice', {
              valueAsNumber: true,
            })}
            error={errors.dickRatePrice?.message}
          />
          <FormNumericInput
            label="Sexting Price"
            {...register('sextingPrice', {
              valueAsNumber: true,
            })}
            error={errors.sextingPrice?.message}
          />
          <FormNumericInput
            label="Lip Sync Price"
            {...register('lipSyncPrice', {
              valueAsNumber: true,
            })}
            error={errors.lipSyncPrice?.message}
          />
          <FormNumericInput
            label="Text On Body Price"
            {...register('textOnBodyPrice', {
              valueAsNumber: true,
            })}
            error={errors.textOnBodyPrice?.message}
          />
          <FormNumericInput
            label="Tipping Price"
            {...register('tippingPrice', {
              valueAsNumber: true,
            })}
            error={errors.tippingPrice?.message}
          />
        </form>
      </FlexMain>
      <FlexFooter>
        <EditInfluencerButton
          title="Edit influencer"
          onClick={() => handleSubmit(onSubmit)()}
          isLoading={editInfluencerPending}
        />
      </FlexFooter>
      <HandleVoiceProvidersModal
        modalRef={handleVoiceProvidersModalRef}
        onSetVoiceProviders={data => setValue('voiceProviders', data, { shouldValidate: true })}
        voiceProviders={watchVoiceProviders}
      />
      <CropImageModal
        ref={cropImageRef}
        onClose={() => {
          setValue('profilePhoto', null);
          cropImageRef.current.hide();
        }}
        onComplete={croppedImage => {
          setValue('profilePhoto', croppedImage);
          cropImageRef.current.hide();
        }}
        originalImage={getValues('profilePhoto')}
      />
    </>
  );
}

export default InfluencerEditTab;

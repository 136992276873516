import React, { useEffect, useMemo, useState } from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { fetchInfluencerPremiumAlbumMedia } from '../../../store/slices/influencer/asyncThunks';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchInfluencerPremiumAlbumMediaInfo } from '../../../store/slices/influencer/slice';
import { Text20Regular } from '../../utils/texts/text.styles';
import MainLoader from '../../utils/loaders/MainLoader/MainLoader';
import {
  MediaCardContainer,
  ModalContainer,
  PhotosContainer,
  PhotosContent,
  VideosContainer,
  VideosContent,
} from './SelectMediaModal.styled';
import { MEDIA_TYPE } from '../../../utils/constants';
import Image from '../../utils/Image/Image';
import Video from '../../utils/Video/Video';

const SelectMediaModal = ({ modalRef, onSelect, chosenMedia }) => {
  const dispatch = useDispatch();
  const { data, pending } = useSelector(selectFetchInfluencerPremiumAlbumMediaInfo);
  const [selectedMedia, setSelectedMedia] = useState([]);

  useEffect(() => {
    dispatch(fetchInfluencerPremiumAlbumMedia());
  }, [dispatch]);

  const [photos, videos] = useMemo(() => {
    if (data) {
      const filteredPhotos = data.photos.filter(photo => !chosenMedia.some(chosen => chosen.id === photo.id));
      const filteredVideos = data.videos.filter(video => !chosenMedia.some(chosen => chosen.id === video.id));
      return [filteredPhotos, filteredVideos];
    }
    return [[], []];
  }, [data, chosenMedia]);

  const onMediaClick = (media, type) => {
    const foundMedia = selectedMedia.find(selected => selected.id === media.id);
    if (foundMedia) {
      setSelectedMedia(selectedMedia.filter(selected => selected.id !== media.id));
    } else {
      setSelectedMedia([...selectedMedia, { id: media.id, url: media.url, type }]);
    }
  };

  return (
    <FormModal
      ref={modalRef}
      title="Select Media"
      onClose={() => {
        modalRef.current.hide();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Select"
      onRightButtonClick={() => {
        onSelect(selectedMedia);

        modalRef.current.hide();
      }}
      onAfterClose={() => setSelectedMedia([])}
      increasedWidth
      rightButtonDisabled={selectedMedia.length === 0}
      shouldCloseOnOverlayClick={false}>
      <ModalContainer>
        {pending || !data ? (
          <MainLoader />
        ) : (
          <>
            <PhotosContainer>
              <Text20Regular>Photos</Text20Regular>
              <PhotosContent>
                {photos.map(photo => (
                  <MediaCard
                    key={photo.id}
                    item={photo}
                    disabled={photo.isInBundle}
                    type={MEDIA_TYPE.PHOTO}
                    onClick={() => onMediaClick(photo, MEDIA_TYPE.PHOTO)}
                    selected={selectedMedia.some(selected => selected.id === photo.id)}
                  />
                ))}
              </PhotosContent>
            </PhotosContainer>
            <VideosContainer>
              <Text20Regular>Videos</Text20Regular>
              <VideosContent>
                {videos.map(video => (
                  <MediaCard
                    key={video.id}
                    item={video}
                    disabled={video.isInBundle}
                    type={MEDIA_TYPE.VIDEO}
                    onClick={() => onMediaClick(video, MEDIA_TYPE.VIDEO)}
                    selected={selectedMedia.some(selected => selected.id === video.id)}
                  />
                ))}
              </VideosContent>
            </VideosContainer>
          </>
        )}
      </ModalContainer>
    </FormModal>
  );
};

const MediaCard = ({ item, type, selected, onClick, disabled }) => {
  return (
    <MediaCardContainer $selected={selected} $disabled={disabled} onClick={() => !disabled && onClick()}>
      {type === MEDIA_TYPE.PHOTO ? (
        <Image src={item.url} alt="premium-photo-media" onClick={() => {}} disabled={disabled} />
      ) : (
        <Video src={item.url} onClick={() => {}} disabled={disabled} />
      )}
    </MediaCardContainer>
  );
};

export default SelectMediaModal;

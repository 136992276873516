import React, { useEffect, useState } from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchCaptionRecommendationsPending } from '../../../store/slices/influencer/slice';
import { fetchCaptionRecommendations } from '../../../store/slices/influencer/asyncThunks';
import { notifyError } from '../../../utils/notify';
import { CaptionCard, Container, NoCaptionsText } from './GetCaptionRecommendationsModal.styles';
import MainLoader from '../../utils/loaders/MainLoader/MainLoader';

const GetCaptionRecommendationsModal = ({ modalRef, mediaId, setMediaId, setValue }) => {
  const dispatch = useDispatch();
  const fetchCaptionRecommendationsPending = useSelector(selectFetchCaptionRecommendationsPending);
  const [captions, setCaptions] = useState([]);
  const [selectedCaptionIndex, setSelectedCaptionIndex] = useState(0);

  useEffect(() => {
    if (mediaId) {
      dispatch(fetchCaptionRecommendations({ photoId: mediaId }))
        .unwrap()
        .then(payload => {
          setCaptions(payload);
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  }, [mediaId, dispatch]);

  const onSubmit = () => {
    setValue('caption', captions[selectedCaptionIndex]);
    modalRef.current.hide();
  };

  return (
    <FormModal
      ref={modalRef}
      title="Caption Recommendations"
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        setMediaId(null);
        setSelectedCaptionIndex(0);
        setCaptions([]);
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Submit"
      onRightButtonClick={onSubmit}
      rightButtonDisabled={!captions.length || fetchCaptionRecommendationsPending}
      shouldCloseOnOverlayClick={false}>
      <Container>
        {fetchCaptionRecommendationsPending ? (
          <MainLoader />
        ) : !!captions.length ? (
          captions.map((caption, index) => (
            <CaptionCard
              $selected={selectedCaptionIndex === index}
              key={index}
              onClick={() => setSelectedCaptionIndex(index)}>
              {caption}
            </CaptionCard>
          ))
        ) : (
          <NoCaptionsText>No caption recommendations found.</NoCaptionsText>
        )}
      </Container>
    </FormModal>
  );
};

export default GetCaptionRecommendationsModal;
